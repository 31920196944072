.next-steps .fa-link {
    margin-right: 5px;
}

body{
    background-color: #43566a;
}

/* Fix for use only flexbox in content area */

.next-steps .row {
    margin-bottom: 0;
}

.next-steps .col-md-5 {
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .next-steps .col-md-5 {
        margin-bottom: 0;
    }
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.result-block-container .result-block {
    opacity: 1;
}

.logo {
    background-image: url('./assets/logo.png');
    width: 315px;
    height: 55px;
    background-repeat: no-repeat;
    background-size: cover
}

.navbar {
    background:#43566a;
    box-shadow: none;
}

footer {
    background:#43566a;
    box-shadow: none;
}

.btn-primary{
    background-color: #369DA8;
    border: none;
    color: white;
    border-radius: 10px;
}

.str-chat{
    height: 87vh;
}